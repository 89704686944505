import React, { useState, useCallback } from 'react';
import './ContactUs.css';
import prostheticArmImage from '../assets/prosthetic-arm.png';
import useScrollRotation from '../hooks/useScrollRotation';

interface FormData {
  email: string;
  message: string;
}

const ContactUsPage: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({ email: '', message: '' });
  const [status, setStatus] = useState<string>('');
  const { elementRef, wrapperRef } = useScrollRotation();

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  }, []);

  const handleSubmit = useCallback(async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setStatus('Sending...');

    try {
      const response = await fetch('/.netlify/functions/submit-form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        const result = await response.json();
        setStatus(result.message);
        setFormData({ email: '', message: '' });
      } else {
        throw new Error('Form submission failed');
      }
    } catch (error) {
      // console.error('Error:', error); // Commented out for production
      setStatus('Failed to send message. Please try again.');
    }
  }, [formData]);

  return (
    <div className="contact-page-wrapper" ref={wrapperRef}>
      <div className="contact-page">
        <div className="prosthetic-arm-container">
          <div 
            className="prosthetic-arm" 
            ref={elementRef} 
            style={{backgroundImage: `url(${prostheticArmImage})`}}
          ></div>
        </div>
        <div className="content">
          <h1>Book a Demo</h1>
          <form onSubmit={handleSubmit} data-netlify="true" name="contact" method="POST">
            <input type="hidden" name="form-name" value="contact" />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Your email"
              required
            />
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Your message"
              required
            ></textarea>
            <button type="submit">Send</button>
          </form>
          {status && <p className="status-message">{status}</p>}
        </div>
      </div>
    </div>
  );
};

export default ContactUsPage;