import React, { useRef, useCallback, useState } from 'react';
import './HomePage.css';
import prostheticArmImage from '../assets/prosthetic-arm.png';
import useScrollRotation from '../hooks/useScrollRotation';
import WaitlistForm from '../components/WaitlistForm';

const HomePage: React.FC = () => {
  const { elementRef, wrapperRef } = useScrollRotation();
  const missionRef = useRef<HTMLElement>(null);
  const [showWaitlistForm, setShowWaitlistForm] = useState(false);

  const scrollToMission = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    const targetPosition = missionRef.current?.getBoundingClientRect().top ?? 0;
    const startPosition = window.pageYOffset;
    const distance = targetPosition + startPosition;
    const duration = 2000; // Adjust this value to change the scroll duration (in milliseconds)
    let start: number | null = null;

    const step = (timestamp: number) => {
      if (!start) start = timestamp;
      const progress = timestamp - start;
      const percentage = Math.min(progress / duration, 1);

      window.scrollTo(0, startPosition + distance * easeInOutCubic(percentage));

      if (progress < duration) {
        window.requestAnimationFrame(step);
      }
    };

    window.requestAnimationFrame(step);
  }, []);

  // Easing function for smooth scrolling
  const easeInOutCubic = (t: number): number => {
    return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
  };

  const handleJoinWaitlist = (event: React.MouseEvent) => {
    event.preventDefault();
    // console.log('Join Waitlist button clicked'); // Commented out for production
    setShowWaitlistForm(true);
  };

  return (
    <div className="home-page-wrapper" ref={wrapperRef}>
      <div className="home-page">
        <section className="hero">
          <div className="content">
            <h1>morph.</h1>
            <p>Building AI-powered Prosthetics and Robotics of the Future</p>
            <a href="#mission" className="cta-button" onClick={scrollToMission}>Learn More</a>
          </div>
          <div className="prosthetic-arm-container">
            <div 
              className="prosthetic-arm" 
              ref={elementRef} 
              style={{backgroundImage: `url(${prostheticArmImage})`}}
            ></div>
          </div>
        </section>
        <section className="mission-section" ref={missionRef} id="mission">
          <h2>Our Mission</h2>
          <p>At Morph, we're dedicated to improving lives by bridging the gap for amputees by creating affordable,
          responsive prosthetics that have dynamic functionality without the need for surgery.</p>
        </section>
        <section className="coming-soon-section">
          <h2>Coming Soon</h2>
          <p>Domina 1.0</p>
          <button 
            className="cta-button" 
            onClick={handleJoinWaitlist}
            style={{ position: 'relative', zIndex: 10 }}
          >
            Join Waitlist
          </button>
        </section>
      </div>
      {showWaitlistForm && <WaitlistForm onClose={() => setShowWaitlistForm(false)} />}
    </div>
  );
};

export default HomePage;