import React from 'react';
import './TeamPage.css';
import prostheticArmImage from '../assets/prosthetic-arm.png';
import useScrollRotation from '../hooks/useScrollRotation';

//Team images
import sohamImage from '../assets/soham.jpg';
import nickImage from '../assets/nick.jpg';
import andreImage from '../assets/andre.jpg';
import pranaiImage from '../assets/pranai.jpg';
import edwardImage from '../assets/edward.jpg';

interface TeamMember {
  name: string;
  bio: string;
  image: string;
  linkedin: string;
}

const teamMembers: TeamMember[] = [
  {
    name: "Nick Cadavid",
    bio: "Nick is a mechanical engineer student at FSU with experience building robotics and a passion for creating advanced prosthetics.",
    image: nickImage,
    linkedin: "https://www.linkedin.com/in/nickcadavid/",
  },
  {
    name: "Pranai Reddy",
    bio: "Pranai is a business management student at Rice University with experience in venture capital and the healthcare industry.",
    image: pranaiImage,
    linkedin: "https://www.linkedin.com/in/pranaireddy/",
  },
  {
    name: "Soham Mehra",
    bio: "Soham is an industrial and systems engineering student at Virginia Tech with experience in AI/ML in the mortgage and healthcare industries.",
    image: sohamImage,
    linkedin: "https://www.linkedin.com/in/soham-mehra/",
  },
  {
    name: "André Tregear",
    bio: "André is a Finance and MIS student at FSU with experience in electronics and hardware design.",
    image: andreImage,
    linkedin: "https://www.linkedin.com/in/andré-tregear-4613a6258/",
  },
  {
    name: "Edward Hyland",
    bio: "Edward is a finance and economics graduate from the University of Alabama with experience in finance and accounting across various industries.",
    image: edwardImage,
    linkedin: "https://www.linkedin.com/in/edwardgeorgehyland/",
  }
];

const TeamPage: React.FC = () => {
  const { elementRef, wrapperRef } = useScrollRotation();

  return (
    <div className="team-page-wrapper" ref={wrapperRef}>
      <div className="team-page">
        <div className="prosthetic-arm-container">
          <div 
            className="prosthetic-arm" 
            ref={elementRef} 
            style={{backgroundImage: `url(${prostheticArmImage})`}}
          ></div>
        </div>
        <div className="content">
          <h1>The Team</h1>
          <div className="team-section">
            <h2>Founders</h2>
            <div className="team-members">
              {teamMembers.map((member, index) => (
                <div key={index} className="team-member">
                  <a href={member.linkedin} target="_blank" rel="noopener noreferrer" className="member-image-link">
                    <img src={member.image} alt={member.name} className="member-image" />
                  </a>
                  <div className="member-info">
                    <h3>{member.name}</h3>
                    <p className="member-bio">{member.bio}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamPage;
